import React, { useState } from "react"
import { MainLayout } from "../layouts/main.layout"
import Seo from "../components/seo"
import { Helmet } from "react-helmet"
import hero from "../assets/img/priorityHero.png"
import option1 from "../assets/img/priorityLetters.png"
import option2 from "../assets/img/uspsTrackingIntegration.png"
import option3 from "../assets/img/dynamicTrackingPortal.png"
import option4 from "../assets/img/flexibleMailingOptions.png"
import ABanner from "../components/features/ab.component"

import envelope from "/src/assets/img/envelopSliderIcon.svg"
import letter from "/src/assets/img/letterSliderIcon.svg"
import monitor from "/src/assets/img/monitorSliderIcon.svg"
import reward from "/src/assets/img/rewardSliderIcon.svg"

import dynamic from "/src/assets/img/dynamic.svg"
import competetive from "/src/assets/img/technology.svg"
import service from "/src/assets/img/next-day-service.svg"
import secure from "/src/assets/img/secure.svg"
import legal from "/src/assets/img/legal.svg"
import business from "/src/assets/img/business.svg"
import government from "/src/assets/img/goverment.svg"
import individuals from "/src/assets/img/individuals.svg"

const CertifiedMail = ({ location }) => {
  const [expandedBlock, setExpandedBlock] = useState(1)
  const [activeImage, setActiveImage] = useState(option1)
  const [isFading, setIsFading] = useState(false)

  const blocks = [
    {
      id: 1,
      title: "Envelope and Box Options",
      description: `<div style="display: flex; gap: 10px;">
        <div style="padding: 12px 16px; display: flex; flex-direction: column; gap: 10px; border: 1px solid #1880FF; border-radius: 8px; background: #fff; font-family: 'Comfortaa'; font-size: 1vw; color: #000"><span style="font-weight: 600">USPS Priority Mail Flat Rate <br /> Envelope <br /> </span> <span style="font-weight: 300; color: #484848; font-size: 0.8vw; line-height: 1vw;">Capable of holding up to 100 physical pages, including a cover sheet, making it ideal for large document mailings.</span></div>
        <div style="padding: 12px 16px; display: flex; flex-direction: column; gap: 10px; border: 1px solid #1880FF; border-radius: 8px; background: #fff; font-family: 'Comfortaa'; font-size: 1vw; color: #000"><span style="font-weight: 600">USPS Priority Mail Box</span>    <span style="font-weight: 300; color: #484848; font-size: 0.8vw; line-height: 1vw">Designed to hold up to 1250 physical pages printed, perfect for bulk document shipments.</span></div>
      </div>`,
      imgSrc: option1,
      icon: envelope,
    },
    {
      id: 2,
      title: "Cost-Effective Alternative to FedEx and UPS",
      description: `<div style="padding: 5px 5px; display: inline-block;">Our Priority Mail services provide a cost-effective alternative to traditional carriers like FedEx and UPS, offering similar delivery speed and tracking capabilities at a fraction of the cost. Whether you’re sending business documents, legal paperwork, or bulk mailings, Priority Mail ensures that your shipments arrive on time without breaking your budget.</div>`,
      imgSrc: option2,
      icon: reward,
    },
    {
      id: 3,
      title: "Tracking and Delivery Confirmation",
      description: `<div style="padding: 0px 5px; display: inline-block; font-weight: 600">Our Priority Mail services include:</div> <br />
      <ul style="display: flex; flex-wrap: wrap; list-style-type: disc; list-style-position: inside;">
        <li style="padding: 0px 15px; font-size: 14px;">Real-time tracking from entry into the USPS mail stream to final delivery</li>
        <li style="padding: 0px 15px; font-size: 14px;">Comprehensive tracking history accessible through our dynamic portal</li>
        <li style="padding: 0px 15px; font-size: 14px;">Proof of delivery for complete peace of mind</li>
      </ul> 
      <div style="padding: 0px 5px; margin-top: 10px; display: inline-block; font-size: 14px;">Our dynamic tracking portal allows you to monitor your mailings, access tracking events, and view delivery confirmations, ensuring complete visibility throughout the mailing process.
      </div>`,
      imgSrc: option3,
      icon: monitor,
    },
    {
      id: 4,
      title: "Flexible Mailing Options",
      description: `<div style="padding: 0px 5px; display: inline-block; font-weight: 600">We streamline the mailing process for both single and bulk shipments:</div> <br />
     <ul style="display: flex; flex-wrap: wrap; list-style-type: disc; list-style-position: inside;">
    <li style="padding: 0px 15px; font-size: 14px;"><span style="font-weight: 600">PDF Extraction Tool: Easily extract recipient names and addresses.</li>
    <li style="padding: 0px 15px; font-size: 14px;"><span style="font-weight: 600"> Import from File Method:</span> Import your mailing lists seamlessly.</li>
    <li style="padding: 0px 15px; font-size: 14px;"><span style="font-weight: 600">Comprehensive API: </span>Integrate with your existing systems.</li>
    </ul>
 
      <div style="padding: 0px 5px; margin-top: 10px; display: inline-block; font-size: 14px;">All tracking event history, letter images, and delivery confirmations are stored in our system for up to seven years, with easy access through our interface or API.
      </div>`,
      imgSrc: option4,
      icon: letter,
    },
  ]

  const toggleBlock = blockId => {
    if (expandedBlock !== blockId) {
      setIsFading(true)
      setTimeout(() => {
        setExpandedBlock(blockId)
        setActiveImage(blocks.find(block => block.id === blockId).imgSrc)
        setIsFading(false)
      }, 500) // This should match the CSS transition duration
    }
  }

  return (
    <>
      <MainLayout location={location} isAbsolute={true}>
        <Helmet>
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=G-BD1ZRJNF9F`}
          ></script>
          <script>
            {`
            window.dataLayer = window.dataLayer || [];
            function gtag() {
              dataLayer.push(arguments);
            }
            gtag('js', new Date());
            gtag('config', 'G-BD1ZRJNF9F');
          `}
          </script>
        </Helmet>
        <div className="introSlideCertifiedWrapper">
          <div className="introSlideCertified">
            <div className="slideContent">
              <div className="whatIsCont gutter120">
                  <div className="certifiedsubTitle">
                    Priority Mail Services
                  </div>
                  <h1>
                    Affordable, Reliable, and  <br /> Fast Shipping Solutions for <br />{" "}
                    Printed Documents
                  </h1>
                  <p className="certifiedTitleDesription">
                  At UploadLetters.com, our Priority Mail services offer a fast and cost-effective solution for sending important documents and packages. Leveraging USPS Priority Mail flat rate options, we provide an efficient alternative to FedEx and UPS, offering the same level of tracking and similar delivery timeframes, but at a more competitive price.
                  </p>
                  <div className="btnHolder getStartedWrapper">
                    <a
                      href="https://app.uploadletters.com/register"
                      className="button intoSlideButton"
                    >
                      <div className="intoSlideButtonText">Get Started</div>
                    </a>
                  </div>
                  <img src={hero} alt="hero"  className="certifiedHero"/>
              </div>
            </div>
          </div>
        </div>

        <div className="whatWeDoSlide" id="what-we-do">
          <div className="slideContent whatWeDoContBG">
            <div className=" whatIsContMain ">
              <div className="whatWeDoTitleBlock">
                <div>
                  <h1 className="whatWeDocertifiedsubTitle">
                  What is Priority Mail?
                  </h1>
                </div>
                <div className="whatWeDoDescription">
                Priority Mail is a USPS service that offers expedited delivery for packages and letters, typically within 1-3 business days. It includes tracking and delivery confirmation, making it an ideal choice for sending printed documents and other time-sensitive items. Priority Mail is especially popular for its flat rate options, allowing you to ship items without worrying about weight or distance.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="whoUsesCont" style={{ background: "#f2f3fc" }}>
          <h1 className="whatIsTitle">Who Uses Priority Mail?</h1>
          <div className="whoUsesContBlock">
            <div className="whatIsBlock">
              <div className="whatIsImgBlock">
                <div className="whatWeDoTitle">Businesses</div>
                <img src={legal} alt="Phone" className="whatIsImg" />
              </div>
              <div className="contactUsBlockDescr">
              For sending contracts, legal documents, and marketing materials.
              </div>
            </div>
            <div className="whatIsBlock">
              <div className="whatIsImgBlock">
                <div className="whatWeDoTitle">Legal Professionals</div>
                <img src={business} alt="Phone" className="whatIsImg" />
              </div>
              <div className="contactUsBlockDescr">
              To quickly and securely deliver time-sensitive legal documents.
              </div>
            </div>
            <div className="whatIsBlock">
              <div className="whatIsImgBlock">
                <div className="whatWeDoTitle">Government Agencies</div>
                <img src={government} alt="Phone" className="whatIsImg" />
              </div>
              <div className="contactUsBlockDescr">
              For distributing official documents, notices, and sensitive communications.
              </div>
            </div>
            <div className="whatIsBlock">
              <div className="whatIsImgBlock">
                <div className="whatWeDoTitle">Individuals</div>
                <img src={individuals} alt="Phone" className="whatIsImg" />
              </div>
              <div className="contactUsBlockDescr">
              To send personal documents, tax forms, or urgent letters requiring quick delivery.
              </div>
            </div>
          </div>
        </div>
        <div className="gutter60">
          <div className="mainSliderContainer paddingRight">
            <div className="splitContainer">
              <div className="optionsContainer">
                <h1 className="typesTitle textLeft">
                Our Priority Mail Offerings
                </h1>
                {blocks.map(block => (
                  <div
                    key={block.id}
                    className={`optionBlock ${
                      expandedBlock === block.id ? "expanded" : ""
                    }`}
                    onClick={() => toggleBlock(block.id)}
                  >
                    <div className="optionIconBlock">
                      <img src={block.icon} alt="icon" className="optionIcon" />
                    </div>
                    <div>
                      <div className="optionTitleBlock">
                        <div className="optionTitle">{block.title}</div>
                      </div>
                      {expandedBlock === block.id && (
                        <p
                          className="optionContent"
                          dangerouslySetInnerHTML={{
                            __html: block.description,
                          }}
                        ></p>
                      )}
                    </div>
                  </div>
                ))}
              </div>
              <div
                className={`imageContainer ${
                  isFading ? "fade-out" : "fade-in"
                }`}
              >
                <img src={activeImage} alt="Selected Option" />
              </div>
            </div>
          </div>
        </div>

        <div
          className="mainContainer whatIsCont"
          style={{ background: "#f2f3fc" }}
        >
          <h2 className="whatIsTitle">Why Choose Us?</h2>
          <div className="whyChooseContBlock">
            <div className="advantagesItem">
              <img src={dynamic} className="advantagesCheckbox" />
              <div className="advantagesDescription">
                <span className="whatWeDoTitle">Cost-Effective Shipping:</span> <br />
                Save money with competitive pricing, especially for large volumes.
              </div>
            </div>

            <div className="advantagesItem">
              <img src={competetive} className="advantagesCheckbox" />
              <div className="advantagesDescription">
                <span className="whatWeDoTitle">Comprehensive Tracking:</span>{" "}
                <br />
                Keep track of your mailings from start to finish with our dynamic tracking portal.
              </div>
            </div>
          </div>

          <div className="whyChooseContBlock">
            <div className="advantagesItem">
              <img src={service} className="advantagesCheckbox" />
              <div className="advantagesDescription">
                <span className="whatWeDoTitle">Next Day Service:</span> <br />
                All orders placed by 2:00 PM CST are mailed the very next
                business day.
              </div>
            </div>

            <div className="advantagesItem">
              <img src={secure} className="advantagesCheckbox" />
              <div className="advantagesDescription">
                <span className="whatWeDoTitle">
                Flexible Solutions:
                </span>{" "}
                <br />
                Whether for personal or business use, we offer solutions that fit your mailing needs.
              </div>
            </div>
          </div>
        </div>
        <div className="mainContainer gutter60">
          <ABanner />
        </div>
      </MainLayout>
    </>
  )
}

CertifiedMail.defaultProps = {
  location: {},
}

export default CertifiedMail

export const Head = () => (
  <Seo
    title="UploadLetters.com - Certified Mail"
    description="Trust UploadLetters.com for online mailing. Explore Certified, First-Class, and Priority Mail solutions, redefining the mailing experience."
  />
)
